<template>
  <module
      ref="module"
      id="allaccounts"
      titleIcon="fa fa-th-large"
      :title="$t('accountstxt')"
      :use-default-list="false"
    >
    <div slot="toolbar-global" v-if="inprogress == false && clonedAccounts.length > 0 && !isbroker">
      <fg-input class="input-sm"
                :placeholder="searchtxt"
                v-model="searchuser"
                addon-right-icon="nc-icon nc-zoom-split"
                v-on:keyup="searchaccs">
      </fg-input>
    </div>
    <div slot="global">

      <!-- In progress -->
      <div v-if="inprogress" class="text-center">
        <h2>{{ inprogresstxt }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>

      <div class="row" v-if="inprogress == false && accounts.length > 0 && !isbroker">
        <div class="col-lg-3 col-md-6 col-sm-6" v-for="acc in accounts">

          <card type="pricing" class="card-category">
            <h6 slot="header" class="card-category">
              {{ acc.first_name }} {{ acc.last_name }}<br/>
              {{ acc.userid }}
            </h6>
            <div class="account-count">
              {{ acc.count }}
            </div>
            <p-button type="primary" round slot="footer" v-on:click="openAccount(`${acc.userid}`)">{{ $t('openaccount') }}</p-button>
          </card>

        </div>
      </div>

      <div class="row" v-if="inprogress == false && clonedAccounts.length > 0 && isbroker">
        <div class="card col-md-12">
          <div class="card-body table-full-width row">
            <div class="col-sm-2">
              <el-select class="select-default" v-model="sortby.column" :placeholder="sortbyTxt"
                v-on:change="sortByCol">
                <el-option class="select-default"
                  v-for="item in sortby.columns"
                  :key="item.prop" :label="item.label" :value="item.prop">
                </el-option>
              </el-select>
            </div>

            <div class="col-sm-2">
              <el-select
                class="select-default" v-model="pagination.perPage" :placeholder="perpageTxt">
                <el-option class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </div>

            <div class="col-sm-2">
              <el-select
                class="select-default" v-model="filterby.element" :placeholder="filterbyElement"
                v-on:change="filterByElement">
                <el-option class="select-default"
                  v-for="item in filterby.elements"
                  :key="item.prop" :label="item.label" :value="item.prop">
                </el-option>
              </el-select>
            </div>

            <div class="col-sm-2">
              <el-select
                class="select-default" v-model="sortby.order" :placeholder="sortbyOrder"
                v-on:change="sortByCol">
                <el-option class="select-default"
                  v-for="item in sortby.orders"
                  :key="item.prop" :label="item.label" :value="item.prop">
                </el-option>
              </el-select>
            </div>

            <div class="col-sm-2">
              <vue-excel-xlsx
                style="top: -10px;position: relative;"
                class="btn btn-round btn-info"
                :data="exportdata"
                :columns="exportcols"
                :filename="exportfilename"
                :sheetname="exportsheetname">
                  <i class="fa fa-download"></i> {{ $t('exportexcel') }}
              </vue-excel-xlsx>
            </div>


            <div class="col-sm-2">
              <div class="pull-right">

                <fg-input class="input-sm"
                          :placeholder="searchtxt"
                          v-model="searchuser"
                          addon-right-icon="nc-icon nc-zoom-split"
                          v-on:keyup="searchit">
                </fg-input>

              </div>
            </div>

            <div class="col-sm-12 mt-2">
              <el-table class="table-striped"
                        :data="queriedData"
                        border
                        style="width: 100%">
                <el-table-column v-for="column in tableColumns"
                                 :key="column.label"
                                 :min-width="column.minWidth"
                                 :prop="column.prop"
                                 :label="column.label">
                </el-table-column>

              </el-table>
            </div>
            <div class="col-sm-6 pagination-info">
              <p class="category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.total">
              </p-pagination>
            </div>
          </div>
        </div>
      </div>

  </div>
  </module>
</template>
<script>
  import Vue from 'vue'
  import {Card} from 'src/components/UIComponents'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import VueExcelXlsx from "vue-excel-xlsx";

  Vue.use(VueExcelXlsx)
  Vue.use(Select)
  Vue.use(Option)
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(require('vue-moment'))

  const user_data = JSON.parse(localStorage.getItem('user-info'));

  export default {
    components: {
      Card, PPagination
    },
    data () {
      return {
        inprogress: true,
        accounts: [],
        clonedAccounts: [],
        searchuser: null,
        searchtxt: this.$t('searchtxt'),
        isbroker: false,
        perpageTxt: this.$t('perpage'),
        sortbyTxt: this.$t('sortby'),
        sortbyOrder: this.$t('sortbyorder'),
        searchQuery: '',
        propsToSearch: ['account', 'userid', 'strat_name'], filterbyElement: this.$t('filterbyelement'),
        colSort: 'account', filterEl: 'all', filteredAccounts: [],
        exportcols: [
          { label: this.$t('account'), field: "account" }, { label: this.$t('userid'), field: "userid" },
          { label: this.$t('balance'), field: "balance" }, { label: this.$t('equity'), field: "equity" },
          { label: this.$t('strategytxt'), field: "strat_name" }
        ],
        exportdata: [],
        exportfilename: null,
        exportsheetname: this.$t('statementtxt'),
        filterby: {
          element: 'all',
          elements: [
            {
              prop: 'all',
              label: this.$t('alltxt')
            },
            {
              prop: 'fol',
              label: this.$t('connected')
            },
            {
              prop: 'nonfol',
              label: this.$t('unassigned')
            }
          ]
        },
        sortby: {
          column: 'account',
          order: 'asc',
          orders: [
            {
              prop: 'asc',
              label: this.$t('ascending')
            },
            {
              prop: 'desc',
              label: this.$t('descending')
            }
          ],
          columns: [
            {
              prop: 'account',
              label: this.$t('account')
            },
            {
              prop: 'userid',
              label: this.$t('userid')
            },
            {
              prop: 'balance',
              label: this.$t('balance')
            },
            {
              prop: 'equity',
              label: this.$t('equity')
            },
            {
              prop: 'strat_name',
              label: this.$t('strategytxt')
            }
          ]
        },
        pagination: {
          perPage: 5,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        tableColumns: [
          {
            prop: 'account',
            label: this.$t('account')
          },
          {
            prop: 'userid',
            label: this.$t('userid')
          },
          {
            prop: 'balance',
            label: this.$t('balance')
          },
          {
            prop: 'equity',
            label: this.$t('equity')
          },
          {
            prop: 'strat_name',
            label: this.$t('strategytxt')
          }
        ],
        inprogresstxt: this.$t('loadingaccounts')
      }
    },
    computed: {
      pagedData () {
        return this.filteredAccounts.slice(this.from, this.to)
      },
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData () {
        if (!this.searchQuery) {
          this.pagination.total = this.filteredAccounts.length
          return this.pagedData
        }
        let result = this.filteredAccounts
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              //let rowValue = row[key].toString()
              let searchRegex = new RegExp(this.searchQuery, 'gi');
              //if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
              if (row[key].toString().match(searchRegex) !== null) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.filteredAccounts.length
        return this.filteredAccounts.length
      }
    },
    methods: {
      prepForExcel() {
        const now = this.$moment('timezone', 'Europe/London', 'LLLL ss').format("YYYY-MM-DD");
        this.exportdata = this.clonedAccounts.slice(0);
        // Filename and SheetName
        this.exportfilename = this.$t('accountstxt') +"-"+ now;
        this.inprogress = false;
      },
      loadAccounts(resp) {
        if (!resp.success) {
          return;
        }
        this.accounts = resp.data.slice(0);
        this.clonedAccounts = resp.data.slice(0);
        this.inprogress = false;
      },
      _parseAccounts(resp, followers) {

        for (var i=0;i < resp.data.accounts.length;i++) {
          if (resp.data.accounts[i].isDemo) {
            continue
          }
          let a = resp.data.accounts[i];
          a['follower'] = followers;
          if (!followers) {
            a['strat_name'] = this.$t('notavailable');
            if (a['master_strat_name']) {
              a['strat_name'] = a['master_strat_name'];
            }
            a['unbilled'] = this.$t('notavailable');
          }
          this.filteredAccounts.push(a);
        }
        // Load the table
        this.clonedAccounts = this.filteredAccounts.slice(0);
        const baccs = {
          accounts: this.clonedAccounts,
          expiry: this.$moment('timezone', 'Europe/London', 'LLLL ss').add(15, 'm').unix()
        }
        localStorage.setItem('hc_broker-accounts', JSON.stringify(baccs));
        this.prepForExcel();
      },
      _preloadAccountsAllAcounts(brokername) {

        this.clonedAccounts = [];
        this.filteredAccounts = [];

        // Post Preload
        this.$getAllAccounts_v2({
          brokername: brokername,
          follower: false
        }).then(resp => this._parseAccounts(resp, false), this.failop);

        this.$getAllAccounts_v2({
          brokername: brokername,
          follower: true
        }).then(resp => this._parseAccounts(resp, true), this.failop);
      },
      openAccount(userid) {
        if (this.isbroker) {
          location.href = "/accounts/"+ userid;
        } else {
          location.href = "/admin_accounts/"+ userid;
        }
      },
      failop (error) {
        console.log(error);
      },
      searchaccs() {
        if (this.inprogress == false) {
          this.accounts = [];

          let allaccounts = this.clonedAccounts.slice(0);
          let searchu = null;
          if (this.searchuser !== null && this.searchuser.length > 0) {
            searchu = new RegExp(this.searchuser, 'gi');
          }

          for (var i=0;i < allaccounts.length;i++) {
            let addto = true;
            let filter_string = allaccounts[i]['first_name'] +" "+ allaccounts[i]['last_name'];
            filter_string += " " + allaccounts[i]["account"];
            filter_string += " " + allaccounts[i]["userid"];
            filter_string += " " + allaccounts[i]['accounts'].join(",");

            if (searchu !== null && filter_string.match(searchu) === null) {
              addto = false
            }
            // Add to the
            if (addto) {
              this.accounts.push(allaccounts[i]);
            }
          }
        }
      },
      searchit() {
        if (this.inprogress == false) {
          this.filteredAccounts = [];

          let allaccounts = this.clonedAccounts.slice(0);
          let searchu = null;
          if (this.searchuser !== null && this.searchuser.length > 0) {
            searchu = new RegExp(this.searchuser, 'gi');
          }

          for(var i=0;i < allaccounts.length;i++) {
            let addto = true;
            let fullname = allaccounts[i]['userid'] +" "+ allaccounts[i]['account'] +" "+ allaccounts[i]["strat_name"];

            if (searchu !== null && fullname.match(searchu) === null) {
              addto = false
            }
            if (this.filterEl == 'fol' && !allaccounts[i].follower) {
              addto = false;
            }
            if (this.filterEl == 'nonfol' && allaccounts[i].follower) {
              addto = false;
            }
            // Add to the
            if (addto) {
              this.filteredAccounts.push(allaccounts[i]);
            }
          }
        }
      },
      sortItNum (a, b) {
        if (this.sortby.order == 'asc') {
          return a[this.colSort] - b[this.colSort];
        }
        return b[this.colSort] - a[this.colSort];
      },
      sortIt (a, b) {
        if (this.sortby.order == 'asc') {
          if (a[this.colSort] < b[this.colSort])
            return -1;
          if (a[this.colSort] > b[this.colSort])
            return 1;
        }
        if (b[this.colSort] < a[this.colSort])
          return -1;
        if (b[this.colSort] > a[this.colSort])
          return 1;
        return 0;
      },
      sortByCol() {
        this.colSort = this.sortby.column;
        if (this.sortby.column == 'userid' || this.sortby.column == 'account' || this.sortby.column == 'strat_name') {
          this.filteredAccounts.sort(this.sortIt);
        }
        else {
          this.filteredAccounts.sort(this.sortItNum);
        }
      },
      filterByElement() {
        this.inprogress = true;

        this.filterEl = this.filterby.element;
        if (this.filterEl == 'all') {
          this.filteredAccounts = this.clonedAccounts.slice(0);
        }
        else {
          this.filteredAccounts = [];

          for(var i=0;i < this.clonedAccounts.length;i++) {
            if (this.filterEl == 'fol' && this.clonedAccounts[i].follower) {
              this.filteredAccounts.push(this.clonedAccounts[i]);
            }
            if (this.filterEl == 'nonfol' && !this.clonedAccounts[i].follower) {
              this.filteredAccounts.push(this.clonedAccounts[i]);
            }
          }
        }
        // Run the Sort
        this.sortByCol();
        this.inprogress = false;
      }
    },
    mounted () {
      let brokername = null;
      if (user_data['roles'].indexOf('broker') >= 0) {
        this.isbroker = true;
        brokername = user_data['app_metadata']['broker'];
      }
      if (this.isbroker) {
        this._preloadAccountsAllAcounts(brokername);
      } else {
        this.$getAllAccounts_v2({})
          .then(this.loadAccounts, this.failop);
      }
    },
    created(){
      this.$setTranslatingInProgress()
    },
    beforeDestroy(){
      this.$root.$off("showTranslatingProgress")
    }
  }
</script>
<style lang="scss">
  .account-count {
    border-radius: 80px;
    -moz-border-radius: 80px;
    -webkit-border-radius: 80px;
    color: #fbc658;
    display: inline-block;
    font-weight: bold;
    height: 80px;
    line-height: 80px;
    text-align: center;
    min-width: 80px;
    padding: 0 11px;
    font-size: 50px;
    border: 3px solid #fbc658;
  }
</style>
